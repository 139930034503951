<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 17:45:58
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.header-info {
  width: 100%;
  min-height: 58px;
  p { 
    color: #999999;
    font-size: 14px;
    line-height: 58px;
  }
  .current-span {
    color: #666666;
    line-height: 58px;
  }
}
</style>
<template>
  <div class="header-info">
    <p>当前位置：<span>{{ hisPage }}</span><span class="current-span">{{ curPage }}</span></p>
  </div>
</template>

<script>
export default {
  name: "questionHeader",
  props: {
    hisPage: String,
    curPage: String,
  },
  methods: {},
};
</script>
