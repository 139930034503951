<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 19:56:09
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.page-info {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #f7f8fa;
  .info-view {
    display: flex;
    flex-direction: column;
    .header-info {
      width: 100%;
      height: 58px;
    }
    .content-info {
      min-width: 1200px;
      max-width: 1200px;
      height: calc(100% - 58px);
      background-color: white;
      padding: 0 120px;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: scroll;
      margin-bottom: 20px;
      .title {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
        margin-top: 60px;
      }
      .content {
        color: #666666;
        font-size: 18px;
        margin: 30px 0;
        line-height: 30px;
      }
      .content-detail {
        margin-top: 10px;
      }
    }
  }
}
</style>
<template>
  <div class="page-info">
    <div class="info-view">
      <questionnaire-header
        class="header-info"
        :hisPage="pageName"
      ></questionnaire-header>
      <div class="content-info" :style="{maxHeight: (contentHeight)}">
        <div class="title">蚂蚁调研网网络隐私政策</div>
        <p class="content">
          本版发布日期：2022年3月18日
          <br>本版生效日期：2022年3月18日
        </p>
        <p class="content">引言</p>
        <p class="content">
          天津咨臣市场调查有限公司及其子公司（以下称“蚂蚁调研网”或“我们”）非常重视用户个人信息的保护。您在下载、开启、浏览、注册、登录、使用“蚂蚁调研网”网站（idiaoyan.com）、手机应用、等相关客户端内相关产品和/或服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私政策》向您说明，我们将如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。
          <br>在使用蚂蚁调研网各项产品或服务之前，请您务必仔细阅读并透彻理解本政策，在确认充分理解后作出适当选择。勾选“我接受隐私政策”按钮（或其他表示您接受该政策的按钮）表示您同意我们按照本《隐私政策》收集、使用、储存和分享您的相关信息。一旦您使用或在我们更新本《隐私政策》后继续使用我们的产品或服务，即意味着您同意本《隐私政策》及更新后内容并同意我们按照本《隐私政策》处理您的相关个人信息。如果您不同意本《隐私政策》，您应立即停止注册或停止使用我们的产品和/或服务。
          <br>本《隐私政策》中涉及的相关技术词汇，我们尽量以简明扼要的表述，以便您的理解，同时对于《隐私政策》中与您的权益存在重大关系和敏感信息的条款，采用粗体字进行标注以提示您注意。本《隐私政策》中与您的权益存在或可能存在重大关系的条款，我们已采用加粗字体提醒您特别注意。
        </p>
        <p class="content">
          特别说明的是，本《隐私政策》适用于蚂蚁调研网现存有效以及将来可能发布运行的平台、产品、服务，如果我们关联公司的产品和/或服务使用了蚂蚁调研网提供的产品和/或服务但未设独立的隐私政策的，则本《隐私政策》同样适用该部分产品和/或服务。
          <br>本《隐私政策》所指的关联方，是指对蚂蚁调研网而言，有能力直接或间接控制、共同控制或对其施加重大影响的主体，以及与蚂蚁调研网同受一方控制、共同控制或重大影响的主体。
        </p>
        <p class="content">
          本《隐私政策》包含以下内容：
          <br>1.我们如何收集您的个人信息
          <br>2.我们如何存储您的信息
          <br>3.我们如何使用Cookie和同类技术
          <br>4.我们如何使用信息
          <br>5.您如何访问和管理自己的个人信息
          <br>6.我们如何与第三方共享、转让、披露您的个人信息
          <br>7.信息安全
          <br>8.您分享的信息
          <br>9.第三方服务
          <br>10.我们可能向您发送的邮件和信息
          <br>11.未成年人使用我们的产品和/或服务
          <br>12.隐私政策的适用范围
          <br>13.本政策的变更
          <br>14.联系、投诉及举报
          <br>15.产品和/或服务的运营者信息
          <br>16.适用法律与争议解决
          <br>1.我们如何收集您的个人信息
          <br>为了向所有用户提供更好的产品和/或服务，我们可能会收集、储存您的信息。我们收集信息的主要来源有：您向蚂蚁调研网提供的信息、蚂蚁调研网在您使用蚂蚁调研网旗下产品和/或服务过程中获取的信息、您通过我们的产品和/或服务向其他方提供的共享信息。我们将合法合理且最小化收集您的个人信息。蚂蚁调研网将通过以下目的，收集和使用您的个人信息：
          <br>1.1 注册和登录
          <br>如果您使用蚂蚁调研网做商业调研问卷、趣味活动和游戏、积分兑换、签到、抽奖、邀请好友、意见反馈功能，您需要注册/登录（登录即代表您注册成为蚂蚁调研网的用户），我们将根据您注册/登录的方式，收集以下个人信息：
        </p>
        <p class="content">
          1.1.1 手机号码：是您选择使用手机号码注册/登录时，主动向我们提供的个人信息。同时，我们将通过发送短信验证码的方式来验证您的账户是否有效。
          <br>1.1.2 电子邮箱：是您选择使用电子邮箱注册/登录时，主动向我们提供的个人信息。同时，我们将通过发送邮件的方式来验证您的账户是否有效。
          <br>1.1.3 第三方应用账号（微信、QQ、新浪微博）和昵称、头像信息（请以授权登录界面显示的信息为准），是您选择使用第三方账号注册/登录，并授权蚂蚁调研网读取您在该第三方账号注册的上述信息。
          <br>1.2 为您推荐个性化的信息或服务
          <br>为向您提供个性化的信息或服务，向您展示、推荐或推送与您有更高相关度、您更感兴趣的调查问卷，推荐与您相关度更高的程序化广告，我们可能向您收集和使用您的以下个人信息并进行分析：
          <br>a) 您的浏览、收藏、搜索记录。
          <br>b) 您主动提供的点赞、分享、关注、评论的行为信息。
          <br>c) 获得您同意后的地理位置信息。
          <br>d) 您在我们的产品/服务内提交的身份信息（即姓名、职业）、年龄、性别、城市、出生日期、学历、婚姻状况、邮箱、工作时间、收入、有无孩子、手机品牌、汽车品牌、购车日期、兴趣标签
          <br>e) 浏览器自动收集的一些信息，包括浏览器类型、IP地址、设备型号、系统版本
          <br>f) 获取您的设备中安装的其他应用程序列表以及下载后是否唤醒（此权限仅基于Android系统开发）
          <br>g) 如您需要在问卷中插入图片、音频、视频的，需授权我们开启您的相册或相机功能以读取您设备中的相册或图库，我们将向您询问是否开启您设备中的相册或相机功能
          <br>您可以通过不填写问卷、不填写个人资料方式来拒绝提供上述信息，拒绝我们调用或关闭地理位置权限、设备权限以不提供地理位置和设备信息。您不提供上述行为信息的，我们向您展示、推荐或推送的信息或服务会降低精准度和相关度，但不影响您正常使用其他功能。
          <br>如您拒绝或关闭地理位置权限的，我们将不会基于地理位置向您推送信息，但不会影响您正常使用其他不需该权限的功能。
          <br>如您关闭或拒绝开启相册或相机功能权限的，您将无法使用相应的功能，但不会影响您正常使用无需该权限的其他功能。
          <br>您拒绝或关闭设备信息的，我们向您展示、推荐或推送的信息会降低精准度和相关度，但不影响您正常使用其他不需要该权限的功能。
          <br>1.3 提现信息
          <br>如果您想把所获积分奖励兑换成现金时，需要提供您的微信账号信息，以便我们将对应的现金发放到您的微信钱包中。
          <br>1.4 邀请好友加入
          <br>当您将分享链接转发到QQ好友/QQ空间/微信朋友圈/微信好友时，我们会获取对应第三方网站的信息以确保该内容能分享成功。
          <br>1.5 消息推送、站内信和提醒
          <br>若您需要接收我们的消息推送、站内信和提醒（答卷收集提醒、发布问卷提醒），您需要开启相应通知授权，我们可以在第一时间将相关信息推送给您，若未授权通知，不影响您使用我们提供的其他服务。
          <br>1.6 支付费用
          <br>您在购买蚂蚁调研网提供的产品和/或服务时，如您选择以银行卡、微信、支付宝方式支付费用，在选择特定支付方式时您需要提供您的银行卡、微信、支付宝信息我们，以便您购买想要的产品和/或服务。在您的支付过程中，蚂蚁调研网不会向任何第三方提供有关您个人信用卡或银行卡上之隐私资料。您支付时直接跳转到第三方支付页面的，该等第三方将会直接收集您的支付信息，该等信息的收集、使用、储存和分享将遵守第三方的隐私条款，与蚂蚁调研网无关，请您注意事先仔细查看。
          <br>1.7 为提高您使用蚂蚁调研网提供服务的安全性，保护您或其他公众的人身财产安全免遭侵害，更好地预防因钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入、违法违规内容而产生的安全风险，更准确地识别违反法律法规或我们制定的相关协议规则的情况，我们可能使用或整合您的账号信息、交易信息、设备信息、位置信息、日志信息以及我们关联方、第三方合作伙伴获得您授权或依据法律共享的信息，来综合判断您的账号及交易风险、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
          <br>1.7.1日志信息，指您使用我们的产品和/或服务时，我们的系统可能通过cookies、web beacon或其他方式自动采集的技术信息，包括：
          <br>a) 设备或网络信息，例如您的移动设备、网页浏览器或用于接入我们产品和/或服务的其他程序所提供的配置信息、移动设备所用的版本、设备所在位置相关信息（例如设备品牌及型号、设备软件版本信息、IP 地址、GPS位置、MAC地址、国际移动设备识别码（IMEI）、网络使用习惯）等；
          <br>b) 在使用我们产品和/或服务时搜索或浏览的信息，例如您使用的网页搜索词语、浏览器类型、访问的社交媒体页面url地址、访问的日期和时间、系统的错误日志及您访问的网页记录，以及您在使用我们产品和/或服务时浏览或要求提供的其他信息和内容详情等；
          <br>c) 有关您曾使用的移动应用（APP）和其他软件的信息；
          <br>d) 您通过我们的产品和/或服务进行通讯的信息，例如曾通讯的账号，以及通讯时间、数据和时长等；
          <br>e) 通过我们的产品和/或服务分享的内容所包含的信息（元数据），例如拍摄或上传的照片、录音或录像的日期、时间或地点等。
          <br>f) 我们可能会获得您在使用第三方合作伙伴时所产生或分享的信息。例如，您使用微信或QQ账户登录第三方合作伙伴服务时，我们会获得 您登录第三方合作伙伴服务的名称、登录时间，方便您进行授权管理。请您仔细阅读第三方合作伙伴的服务协议或隐私协议。
          <br>1.7.2 位置信息，包括您或其他用户在使用蚂蚁调研网产品和/或服务时向我们提供的包含您所处地理位置的信息。
          <br>1.7.3 为了给您提供完善的产品和/或专属服务，您在使用我们的产品和/或服务时系统所收集的信息，例如为向您提供更契合您个人的调研，我们可能会收集关于您使用的服务以及使用方式的个人信息并将这些个人信息进行关联。
          <br>您可以通过手机设备中“【设置】”-“【应用】”【应用管理/启动管理/权限管理/】中逐项查看您上述权限的开启状态，并可以决定将这些权限随时开启或关闭。请您注意，您开启这些权限代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权相对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。
          <br>1.8 征得授权同意的例外
          <br>根据相关法律法规规定，出现以下情形的，我们收集和使用您的个人信息无需征得您的授权同意：
          <br>a) 与国家安全、国防安全有关的；
          <br>b) 与公共安全、公共卫生、重大公共利益有关的；
          <br>c) 与犯罪侦查、起诉、审判和决定书、裁判执行等有关的；
          <br>d) 出于维护个人信息主体或其他个人的生命、财产重大合法权益但又很难得到您本人同意的；
          <br>e) 所收集的个人信息是您自行向社会公众公开的；
          <br>f) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开、新媒体平台等渠道；
          <br>g) 根据您的要求签订合同所必需的的；
          <br>h) 用于维护所提供的产品或服务的安全稳定所必需的，例如发现、处置产品或服务的故障；
          <br>i) 为合法的新闻报道所必需的；
          <br>j) 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
          <br>k) 法律法规规定的其他情形。
          <br>请您理解并同意，如您的信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；只有当您的信息可以单独或结合其他信息识别到您的个人身份时，则在结合使用期间，将作为您的个人信息按照本政策处理与保护。
          <br>2. 我们如何存储您的信息
          <br>2.1 我们在中华人民共和国境内运营中收集和产生的个人信息，原则上存储在中国境内，以下情形除外：
          <br>a) 法律法规有明确规定；
          <br>b) 获得您的明确授权。
          <br>2.2 我们综合考虑以下要素来确定保存您的个人信息期限：
          <br>a) 法律规定的最低储存期限，如《网络安全法》《电子商务法》等规定的最短保存时间；
          <br>b) 为提供我们的产品/服务之目的所必需的期限，如在您使用手机号码注册/登录后使用相应的功能时，我们会在您提供且使用该功能期间，持续保存您的手机号码，以向您正常提供该功能。
          <br>c) 履行其他法定义务而保存的期限，如根据《信息网络传播保护条例》第十三条规定，我们需向著作权行政管理部门提供涉嫌侵权服务对象的姓名（名称）、联系方式等信息。
          <br>d) 监测、记录网络运行状态、网络安全事件的相关网络日志保存时间不少于六个月。
          <br>2.3 超出上述信息保留期限的个人用户信息，我们会依据相关法律法规以及制定的规定，使用格式化等方式对用户个人信息删除或进行匿名化处理。
          <br>3. 我们如何使用Cookie和同类技术
          <br>3.1 我们或我们的第三方合作伙伴，可能通过cookies和web beacon收集和使用您的信息，并将该等信息储存为日志信息。
          <br>3.2 我们使用自己的cookies和类似技术，目的是为您提供更个性化的用户体验和产品和/或服务，并用于以下用途：
          <br>3.2.1 记住您的身份。例如：cookies有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息；
          <br>3.2.2 分析您使用我们产品和/或服务的情况。例如，我们可利用cookies来了解您使用我们的产品和/或服务进行什么活动，或哪些网页或产品和/或服务最受您的欢迎；
          <br>3.2.3 广告优化。Cookies和web beacon有助于我们根据您的信息，向您提供与我们的产品和/或服务相关的广告而非进行普遍的广告。
          <br>3.3 我们为上述目的使用cookies的同时，可能将通过cookies收集的非个人身份信息，经统计加工后提供给广告商和其他合作伙伴，用于分析用户如何使用我们的产品和/或服务，并用于我们的广告服务。
          <br>3.4 我们的产品和服务上可能会有广告商或其他合作方放置的cookies和web beacon。这些cookies和web beacon可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等产品和/或服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方cookies和web beacon收集和使用该等信息，不受本《隐私政策》约束，而是受到其自身的隐私政策约束，我们不对第三方的cookies或web beacon承担责任。
          <br>3.5 您可以通过浏览器设置拒绝或管理cookies。但请注意，如果停用cookies或web beacon，我们有可能无法为您提供最佳的服务体验，某些产品和/或服务也可能无法正常使用。
          <br>3.6 为了确认您的使用形态与网站的使用状况，我们的网站会记录您设备的IP地址与浏览器的类型，用户的浏览行为和操作行为等，以协助我们改善蚂蚁调研网的设计与编排格式。
          <br>4. 我们如何使用信息
          <br>4.1 我们可能将在向您提供产品和/或服务的过程之中所收集的信息用作下列用途：
          <br>4.1.1 向您提供产品和/或服务，我们所收集和使用的个人信息是为您提供蚂蚁调研网服务的必要条件，如缺少相关个人信息，我们将无法为您提供蚂蚁调研网服务的核心内容；
          <br>4.1.2 在我们提供产品和/或服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；
          <br>4.1.3 帮助我们设计新产品和/或服务，改善我们现有产品和/或服务；
          <br>4.1.4 使我们更加了解您如何接入和使用我们的产品和/或服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；
          <br>4.1.5 向您提供蚂蚁调研网相关的产品和/或服务广告；
          <br>4.1.6 评估我们产品和/或服务中的广告和其他促销及推广活动的效果，并加以改善；
          <br>4.1.7 软件认证或管理软件升级；
          <br>4.1.8 让您参与有关我们产品和/或服务的调查，或者促销及推广等活动；
          <br>4.1.9 经您同意的其他用途。
          <br>4.2 为了让您有更好的体验、改善我们的产品和/或服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项产品和/或服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他产品和/或服务。例如，在您使用我们的一项产品或服务时所收集的信息，可能在另一产品或服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关产品和/或服务中提供了相应选项，您也可以授权我们将该产品和/或服务所收集和储存的信息用于我们的其他产品和/或服务。
          <br>4.3 为了给您提供完善的产品和/或服务，提升您的用户体验，在收集您的个人信息后，我们可能会对用户数据库进行分析并予以商业化的利用。我们将遵循法律法规和合法必要的原则，对相关信息采取必要的安保措施，比如通过技术手段对数据进行去标识化处理等。
          <br>您了解并同意，在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理后的个人信息将无法识别您的身份，在此情况下我们有权使用去标识化的个人信息，对用户数据库进行分析并予以商业化的利用。
          <br>5. 您如何访问和管理自己的个人信息
          <br>5.1 查询您的个人信息
          <br>您可以随时登录自己的账户查看您的个人信息。点击“我的”查询您的头像、用户名；点击“我的”-“个人资料”查看您的手机号、性别、城市、生日、学历、婚姻状况、姓名、邮箱、职业、工作时间、个人月收入、家庭月收入、有无小孩、手机品牌、汽车品牌、购车日期。 
          <br>如果您无法通过上述路径访问和管理该等个人信息，您可以随时通过本《隐私政策》所示联系方式与我们取得联系，我们将在收到您的请求之日起15日内回复您的访问请求。
          <br>5.2 更正个人信息
          <br>个人兴趣标签—您可以通过访问蚂蚁调研网APP页面上的“我的”-“兴趣标签”，随时选择、更改、保存您感兴趣的标签，这样方便您接收更适合您的调研问卷任务。
          <br>账户信息—请您理解，为了避免因频繁修改个人资料以达到匹配问卷要求的现象，我们对个人资料的修改进行了区别更正的权限。其中，对于“汽车品牌、手机品牌、学历、城市、手机、微信、头像、邮箱”您可以随时更正。对于“婚姻状况、个人月收入、家庭月收入、职业、有无小孩”，仅给予您一个自然月修改一次的机会；对于“姓名、生日、性别、工作时间”您无修改权限。
          <br>若确实因为特殊情况，您确需要修改个人信息，您可以通过本《隐私协议》所示联系方式与我们管理员取得联系，由管理员帮助您进行修改。
          <br>解绑微信或手机号—若您需要解绑微信或手机号，您可以通过登录我们蚂蚁调研网网页版（idiaoyan.com），在个人资料页面进行微信和手机号解绑。
          <br>5.3 删除个人信息
          <br>5.3.1 您可以通过本条中列明的方式删除您的部分个人信息。在以下情形中，您可以向我们提出删除个人信息的请求：
          <br>a) 我们处理个人信息的行为违反法律法规；
<br>
          <br>b) 我们收集、使用您的个人信息，却未征得您的明确同意；
<br>
          <br>c) 我们处理个人信息的行为严重违反了与您的约定；
<br>
          <br>d) 您不再使用我们的产品或服务，或您主动注销了账号；
<br>
          <br>e) 若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。当您从我们的服务中删除信息后，我们将不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
          <br>5.4 撤回您的授权
          <br>5.4.1 您可以通过删除个人信息撤回您的授权。
          <br>您可以通过“设置”-“应用”选择关闭权限，以此来撤回您的授权。
          <br>请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务和功能，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
          <br>5.5 获取个人信息副本
          <br>如您需要获取您向我们提供的个人信息以及我们通过本《隐私政策》约定收集您的个人信息，请您通过本《隐私政策》列明的联系方式与我们取得联系并提出此项需求，我们将在15日内处理您的请求。
          <br>5.6 账号注销
          <br>1. 您可以通过“我的”-“右上角设置”-“账户与安全”-“安全中心”-“注销账户”进行注销账户操作，我们将在一天内核实用户身份后进行处理；账号注销后，我们将对已收集的个人信息进行删除或匿名化处理，且30天内无法再次进行注册账号。
<br>
          <br>2. 若您的账号自注册成功之日起超过两年无登录记录，我们将保留注销该帐户的权利。该账号一旦注销，我们不接受用户申请解冻或找回账号，相应的会员名称将开放给任意用户注册使用。
          <br>5.7 管理您的定向推送
          <br>5.7.1 我们所提供的“消息推送”是由您自主选择开启或关闭的功能，用于接收我们推送的重要通知。您可以通过以下方式来管理和选择您的“定向推送”：点击“我的”-消息推送进行关闭或开启。
<br>
          <br>5.7.2 您的上述操作可以实现自主控制个性化信息或服务所依赖的个人信息，使您具有调控个性化信息展示相关程度的能力。
          <br>5.8 响应您的上述请求
          <br>5.8.1 为保障安全，您需要向我们主动提出请求，或以其他方式证明您的身份。我们会先要求您验证自己的身份，然后再处理您的请求。 5.8.2. 我们将在收到您的请求后15日内做出答复。如您不满意，还可以通过本《隐私政策》列明的联系方式发起投诉。
<br>
          <br>5.8.3 对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
<br>
          <br>5.8.4 在以下情形中，按照法律法规要求，我们将无法响应您的请求：
<br>
          <br>a) 与国家安全、国防安全有关的；
<br>
          <br>b) 与公共安全、公共卫生、重大公共利益有关的；
<br>
          <br>c) 与犯罪侦查、起诉、审判和执行判决、裁定或行政机关的决定书等有关的；
<br>
          <br>d) 有充分证据表明个人信息主体存在主观恶意或滥用权利的；
<br>
          <br>e) 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
<br>
          <br>f) 涉及商业秘密的。
          <br>5.9 停止运营
          <br>如我们停止运营，将及时停止继续收集您的个人信息的活动，会将停止运营的通知以公告的形式通知您，并对所持有您的个人信息进行删除或匿名化处理。
          <br>6. 我们如何与第三方共享、转让、披露您的个人信息
          <br>6.1 共享
          <br>6.1.1 我们会以高度的勤勉义务对待您的信息，除以下情形外，未经您同意，我们不会与任何第三方分享您的个人信息：
          <br>a) 事先获得您明确的同意或授权；
<br>
          <br>b) 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
<br>
          <br>c) 在法律法规允许的范围内，为维护蚂蚁调研网、蚂蚁调研网的关联方或合作伙伴、您或其他蚂蚁调研网用户或社会公众利益、财产或安全免遭损害而有必要提供；
<br>
          <br>d) 只有共享您的信息，才能实现我们和/或关联公司、与我们合作的第三方提供的产品和/或服务的核心功能或提供您需要的服务；
<br>
          <br>e) 应您需求为您处理您与他人的纠纷或争议；
<br>
          <br>f) 符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；

          <br>g) 基于学术研究而使用；
<br>
          <br>h) 国家及行业主管部门法律法规规定的需要向特定第三方（如第三方审计机构、企业上市监管部门）披露的信息；
<br>
          <br>i) 蚂蚁调研网及关联方内部的必要共享。我们只会共享必要的个人信息，且受本《隐私政策》中所作声明目的的约束。如要改变个人信息的处理目的，将再次征求您的授权同意。
<br>
          <br>j) 基于符合法律法规的社会公众利益而使用。
          <br>6.1.2 为提供更好的客户服务和用户体验，维护和改善我们的产品/服务，我们可能会与第三方共享您的个人信息。我们仅会出于合法、正当、必要的目的提供您的个人信息，并且只会提供相关产品和/或服务所必要的个人信息。我们将努力要求该等第三方在使用您的个人信息时遵守本《隐私政策》及我们要求其遵守的其他适当的保密和安全措施，并明确要求该等第三方仅将共享的个人信息用于与蚂蚁调研网产品和/或服务相关的特定用途。如要改变个人信息的处理目的，该等第三方将再次征求您的授权同意。如您不同意，请您立即停止使用我们所涉及的产品/服务，但不影响您使用其他不涉及的产品/服务。该第三方包括以下类型：
          <br>a) 供应商/服务提供商。仅为实现本隐私政策中声明的目的，我们的某些服务将由业务合作伙伴提供，以保障为您提供的服务顺利完成，我们可能会将您的个人信息共享给上述合作伙伴，包括技术服务、支付服务、内容服务，其中可能包括您的联络信息、订单信息、支付信息、地址信息等，以保证为您提供的服务顺利完成。
          <br>例如1) 如您通过第三方账号（微信、QQ、微博）直接登录我们时，其SDK可能会收集您的设备及网络信息；2) 当您把蚂蚁调研网的项目问卷分享到第三方平台（微信、QQ、微博）时，其SDK可能会收集您的设备及网络信息；3)当您上传图片、导入文件、文件存储时，七牛云储存SDK可能会收集您提供的设备、网络及资料信息；4)当您使用支付宝、微信、银行账户支付时，其SDK会获取您的设备信息及日志信息；5)当您打开了问卷中视频内容，腾讯、优酷会获取您的设备及网络信息；6)百度统计、GIO、友盟、Kibana提供用户行为统计，其SDK可能会获取您的设备及网络信息；7)高德地图SDK提供定位服务，需要您开启联网、定位权限；8）享玩为您提供虚拟币提现服务，其SDK需要收集您的设备及网络信息、日志信息；9）当您登录淘宝、京东、拼多多领取优惠券时，其SDK（阿里百川、京东、拼多多）需要收集您的设备信息、网络信息、位置信息及日志信息。
          <br>上述供应商、服务提供商和其他合作伙伴收集和处理信息等行为遵守其自身的隐私政策，而不适用于本隐私政策。同时，为了最大程度保障您的信息安全，我们建议您在使用任何第三方SDK类服务前先行查看其隐私政策。为保障您的合法权益，如您发现该等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系。
          <br>b) 委托我们进行推广的合作伙伴。我们还可能与委托我们进行推广和广告投放的合作伙伴共享信息，但我们仅会向这些合作伙伴提供推广的覆盖面、有效性以及统计类等信息，而不会提供可以识别您身份的个人信息。
          <br>c) 合作举办营销活动的合作伙伴。我们可能与合作伙伴合作举办营销活动，并与其分享活动过程中产生的、为完成活动所必要的一些信息，以便您能参与活动、我们的合作伙伴能及时与您联系、发放奖品。
          <br>6.1.3 为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护蚂蚁调研网、您或其他蚂蚁调研网客户的权利及其财产或安全，比如为防止欺诈、网络攻击、钓鱼网站、网络漏洞、计算机病毒、网络攻击、网络侵入此类违法活动和减少信用风险，而与其他公司和组织交换信息。不过，这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其他方式披露的个人信息。
          <br>6.2 转让
          <br>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
          <br>a) 事先获得您明确的同意或授权；
          <br>b) 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
          <br>c) 符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
          <br>d) 随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。在转移之前，我们会提前进行告知。如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
          <br>6.3 公开披露
          <br>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
          <br>a) 根据您的要求，在您明确同意的披露方式下披露您所指定的个人信息；
          <br>b) 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。 在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
          <br>6.4 征得同意的例外
          <br>请您理解，以下情形中，共享、转让、公开披露您的个人信息的，无需事先获得您的授权或同意：
          <br>a) 与国家安全、国防安全直接相关的；
          <br>b) 与公共安全、公共卫生、重大公共利益直接相关的；
          <br>c) 与犯罪侦查、起诉、审判和决定书、裁判执行等直接相关的；
          <br>d) 出于维护您或其他个人的生命、财产重大合法权益但又难以得到本人同意的；
          <br>e) 您自行向社会公众公开的个人信息；
          <br>f) 从合法公开披露的信息中收集的您的个人信息，如合法的新闻报道、政府信息公开、新媒体平台等渠道。
          <br>7. 信息安全
          <br>7.1 我们仅在为本《隐私政策》（idiaoyan.com）所述目的和法律法规允许的时限内保留您的个人信息。
          <br>7.2 为了保障您的信息安全，蚂蚁调研网公司会在现有技术水平下采取合理必要的措施来保护您的信息，包括物理、技术和行政管理安全措施来降低丢失、误用、非授权访问、披露和更改的风险，这些保护措施包括：
          <br>a) 我们建立了个人信息相关内控制度，对可能接触到您的个人信息的工作人员采取最小够用授权原则；对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导。
          <br>b) 我们已制定个人信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生个人信息安全事件后，我们将按照法律法规的要求及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话或推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
          <br>c) 我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
          <br>d) 我们的网络服务采取了传输层数据加密的技术，保护用户数据在传输过程中的安全。
          <br>e) 我们采取加密技术对您个人信息进行加密保存，并通过访问限制技术进行隔离。在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、内容隐藏在内多种数据脱敏技术增强个人信息在使用中的安全性
          <br>f) 我们采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
          <br>g) 我们仅允许有必要知晓这些信息的相关员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。
          <br>h) 请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证个人信息百分之百的安全。请您理解并明白，由于存在各种各样无法预知情况，您接入蚂蚁调研网服务所用的系统和通讯网络，有可能因我们可控范围之外的情况而发生问题。
          <br>i) 请您理解，互联网并非绝对安全的环境，我们强烈建议您不要使用非蚂蚁调研网推荐的方式发送个人信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的联系方式、交流信息或分享内容等相关信息的第三方。
          <br>j) 在使用蚂蚁调研网提供的积分兑换、提现服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账号或密码发生泄漏，请您立即联络交易方，如有必要，我们在得到您的请求后，会立即协助处理前述事项。
          <br>7.3 您在使用蚂蚁调研网产品和/或服务时，请妥善保管好您的蚂蚁调研网账号及其密码，我们会通过您的账号及其密码来识别您的身份。使用账号和秘密进行的任何操作、发出的指令都视为您本人做出，因您自身原因造成的账户、密码等信息被冒用、盗用或非法使用，由此造成的风险和损失由您自行承担。一旦您发现蚂蚁调研网账号及其密码因任何原因已经或将受到泄漏时，请您立即联络我们客服，以便我们采取相应措施，但在我们知悉此种情况和在合理时间内采取行动前，我们对此不负任何责任。
          <br>8.您分享的信息
          <br>8.1 我们的产品和/或服务，可让您不仅与自己的社交网络，也与使用该产品和/或服务的其他用户公开分享您的相关信息，例如，您在我们的产品和/或服务中所上传或发布的信息（包括您公开的个人信息）、您对其他人上传或发布的信息作出的回应，以及包括与这些信息有关的位置数据和日志信息。使用我们产品和/或服务的其他用户也有可能分享与您有关的信息（包括位置数据和日志信息）。只要您不删除共享信息，有关信息会一直留存在公共领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的非关联第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公共领域保存。 
          <br>因此，请您谨慎考虑，是否通过我们的产品和/或服务上传、发布和交流相关信息内容。在一些情况下，您可通过联系我们要求从我们的产品和/或服务中删除或者修改您的相关信息。
          <br>8.2 某些个人信息因其特殊性可能被认为是个人敏感信息，例如您的种族、宗教、个人健康和医疗信息等。相比其他个人信息，个人敏感信息受到更加严格的保护。
          <br>8.3 请注意，您在使用我们的产品和/或服务时所提供、上传或发布的内容和信息（例如有关您个人的照片等信息），可能会泄露您的个人敏感信息。您需要谨慎地考虑，是否在使用我们的产品和/或服务时披露相关个人敏感信息。
          <br>8.4 您同意我们可以按本《隐私政策》所述的目的和方式来处理您的个人敏感信息。
          <br>9. 第三方服务
          <br>9.1 我们可能链接至第三方提供的社交媒体，您也可利用“分享”将蚂蚁调研网某些产品或服务分享至第三方平台。
          <br>9.2 我们也可能使用您的信息，通过我们的产品和/或服务、电子邮件或其他方式向您发送营销信息，提供或推广我们或第三方的如下产品和/或服务：
          <br>9.2.1 我们的产品和/或服务，我们的关联公司和合作伙伴的产品和/或服务；
          <br>9.2.2 第三方互联网服务供应商。
          <br>9.3 如您不希望我们将您的个人信息用作前述广告用途，您可以通过点击广告弹窗上的“×”来关闭广告；或通过蚂蚁调研网idiaoyan.com首页底部意见反馈，要求我们停止为上述用途使用您的个人信息，或者停止进行第三方分享。
          <br>10. 我们可能向您发送的邮件和信息
          <br>10.1 邮件和信息推送
          <br>您在使用我们的产品和/或服务时，我们可能使用您的信息向您的设备发送电子邮件、新闻或推送通知。如您不希望收到这些信息，可以按照我们的相关提示，在设备上选择取消订阅。
          <br>10.2 与产品和/或服务有关的公告
          <br>我们可能在必要时（例如因系统维护而暂停某一项服务时）向您发出与产品和/或服务有关的公告。您可能无法取消这些与产品和/或服务有关、性质不属于广告。
          <br>11. 未成年人使用我们的产品和/或服务
          <br>11.1 如您为未满18周岁（含）的未成年人，建议请您的父母或监护人阅读本政策，并在征得您父母或监护人同意的前提下使用我们的服务或向我们提供您的信息。 对于经父母或监护人同意而收集您的信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或公开披露此信息。
          <br>11.2 如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
          <br>12.隐私政策的适用范围
          <br>12.1 除某些特定产品和服务外，蚂蚁调研网旗下所有的产品和/或服务均适用本《隐私政策》。这些特定产品和服务将适用特定的隐私政策。针对某些特定产品和服务的特定隐私政策，将更具体地说明我们在该等产品和服务中如何使用您的信息。该特定产品和/或服务的隐私政策构成本《隐私政策》的一部分。如相关特定服务的隐私政策与本《隐私政策》有不一致之处，适用该特定产品和/或服务的隐私政策。
          <br>12.2 请您注意，本《隐私政策》仅适用于我们所收集、保存、使用、共享、披露信息。除非本《隐私政策》另有明确所指，本《隐私政策》不适用于以下情况：
          <br>a) 通过我们的产品和/或服务而接入的第三方服务（包括任何第三方网站）收集的信息；
          <br>b) 通过在我们产品和/或服务中进行广告服务的其他公司或机构所收集的信息。
          <br>请您知悉：如果您浏览第三方网站或使用第三方的产品和／或服务时向该第三方提供您的个人信息，您的信息应当适用该第三方的隐私声明或类似政策，我们对任何第三方不当使用或披露由您提供的信息不承担任何法律责任，无论您登录或浏览上述网站、软件，或使用其产品和/或服务是否基于蚂蚁调研网的链接或引导。
          <br>13. 本政策的变更
          <br>我们在《隐私政策》发生以下“重大变更”时，我们将在主页显著位置提示或以其他方式通知您。如果您不同意更新后的版本请停止使用我们的产品和/或服务，如果您继续使用我们的产品和/或服务，表示您同意接受修订后的本《隐私政策》的约束：
          <br>a) 我们的核心业务模式发生重大变化。
          <br>b) 我们在所有权结构、组织架构、股权结构等方面发生重大变化。如业务调整、破产、重整、并购、分立等引起的所有者变更等。
          <br>c) 个人信息共享、转让或公开披露的主要对象发生变化。
          <br>d) 您参与个人信息处理方面的权利及其行使方式发生重大改变。
          <br>e) 我们负责受理有关个人信息的投诉、建议和本《隐私政策》 联系方式发生变化。
          <br>如果您还有其他问题和建议，请联系我们。我们会始终致力于充分保护用户信息，并为您提供更优质的体验和服务。
          <br>14. 联系、投诉及举报
          <br>如果您在使用蚂蚁调研网产品/服务过程中发现您的信息被用于违法行为或受到侵害，欢迎您进行积极举报，我们将按照《中华人民共和国网络安全法》等法律的要求受理，您需提供合法有效的权利证明、侵权事实、身份证明等文件以供我们核实，我们将在15日内回复您处理结果，您还可以向网信部门、工信部门、公安及工商等监管部门进行投诉或举报。举报途径如下：
          <br>a服务电子邮件：service@wit-works.com
          <br>b) 客户端举报：“我的”- “意见反馈”
          <br>15. 产品和/或服务的运营者信息
          <br>a) 运营主体：天津咨臣市场调查有限公司
          <br>b) 注册地址：天津市宝坻区海滨街道进京路28号403室
        </p>
        <p class="content">
          16. 适用法律与争议解决
          <br>本《隐私政策》应根据中华人民共和国法律解释与管辖，有关与您和我们之间发生的任何形式的争议均应友好协商解决。协商不成的，任一方均有权将争议提交蚂蚁调研网所在地有管辖权的人民法院诉讼解决。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionnaireHeader from "@/components/QuestionnaireHeader.vue";

export default {
  name: "privacyAgreement",
  components: {
    QuestionnaireHeader,
  },
  data() {
    return {
      pageName: "隐私协议",
      hintTitle: "",
      contentHeight: 0,
    };
  },
  created() {
    this.contentHeight = (window.screen.height - 410) + 'px';
  },
  methods: {
    
  },
};
</script>
